import { AgeGroup, Center, CenterDisplayOptions, Tour, TourPolicy, TourSchedule, WaitlistSpot } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";
import { CenterBasics } from "../infra/utils";

export function findCenters(provider_id: string | unknown, name: string, relationship: string | undefined) {
  return {
    type: actionTypes.CENTER_FIND,
    provider_id,
    name,
    relationship,
  };
}

export function callbackFindCenters(centerList: [Center], errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_FIND_CALLBACK,
    centerList,
    errorCode,
  };
}

export function readCenter(center_id: string) {
  return {
    type: actionTypes.CENTER_READ,
    center_id,
  };
}

export function callbackReadCenter(center: Center[], errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_READ_CALLBACK,
    center,
    errorCode,
  };
}

export function readCenterFamilies(center_id: string, includes: string[]) {
  return {
    type: actionTypes.CENTER_READ_FAMILIES,
    center_id,
    includes,
  };
}

export function callbackReadCenterFamilies(families: any, tours: any, tourRequests: any, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_READ_FAMILIES_CALLBACK,
    families,
    tours,
    tourRequests,
    errorCode,
  };
}

export function readCenterDirectory(partner_id: string | undefined, latitude: number | undefined, longitude: number | undefined,
  county: string | undefined, state: string | undefined) {
  return {
    type: actionTypes.CENTER_READ_DIRECTORY,
    partner_id,
    latitude,
    longitude,
    county,
    state,
  };
}

export function callbackReadCenterDirectory(directory: any, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_READ_DIRECTORY_CALLBACK,
    directory,
    errorCode,
  };
}

export function scoreWaitlists(center_ids: string[], child_id: string, preferred_date: Date) {
  return {
    type: actionTypes.CENTER_SCORE_WAITLISTS,
    center_ids,
    child_id,
    preferred_date,
  };
}

export function callbackScoreWaitlists(scores: Array<{ center_id: string, score: number }>, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_SCORE_WAITLISTS_CALLBACK,
    scores,
    errorCode,
  };
}

export function readCenterProfileCMS(center_id: string) {
  return {
    type: actionTypes.CENTER_READ_PROFILE_CMS,
    center_id,
  };
}

export function callbackReadCenterProfileCMS(cmsData: any, metadata: any, employees: any,
  centerBasics: CenterBasics,
  profileImages: string[] | undefined, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_READ_PROFILE_CMS_CALLBACK,
    cmsData,
    metadata,
    centerBasics,
    employees,
    profileImages,
    errorCode,
  };
}

export function readCenterTours(center_id: string, start: Date, end: Date) {
  return {
    type: actionTypes.CENTER_READ_TOURS,
    center_id,
    start,
    end,
  };
}

export function callbackReadCenterTours(tours: Tour[], errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_READ_TOURS_CALLBACK,
    tours,
    errorCode,
  };
}

export function readCenterTourSchedules(center_id: string) {
  return {
    type: actionTypes.CENTER_READ_TOUR_SCHEDULES,
    center_id,
  };
}

export function callbackReadCenterTourSchedules(tourSchedules: any, vacationDays: any, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_READ_TOUR_SCHEDULES_CALLBACK,
    tourSchedules,
    vacationDays,
    errorCode,
  };
}

export function readTourPolicies(center_id: string) {
  return {
    type: actionTypes.CENTER_READ_TOUR_POLICIES,
    center_id,
  };
}

export function callbackReadTourPolicies(policy: TourPolicy, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_READ_TOUR_POLICIES_CALLBACK,
    policy,
    errorCode,
  };
}
export function updateTourPolicies(center_id: string, policy: TourPolicy) {
  return {
    type: actionTypes.CENTER_UPDATE_TOUR_POLICIES,
    center_id,
    policy,
  };
}

export function callbackUpdateTourPolicies(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_UPDATE_TOUR_POLICIES_CALLBACK,
    success,
    errorCode,
  };
}

export function listAvailableTourTimes(center_id: string, age_group_id: string | undefined, start: Date, days: number) {
  return {
    type: actionTypes.CENTER_LIST_AVAILABLE_TOURS,
    center_id,
    age_group_id,
    start,
    days,
  };
}

export function callbackListAvailableTourTimes(times: Array<{start: Date, duration: number, virtual: boolean}>, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_LIST_AVAILABLE_TOURS_CALLBACK,
    times,
    errorCode,
  };
}

export function readCenterDisplayOptions(center_id: string) {
  return {
    type: actionTypes.CENTER_READ_DISPLAY_OPTIONS,
    center_id,
  };
}

export function callbackReadCenterDisplayOptions(displayOptions: CenterDisplayOptions, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_READ_DISPLAY_OPTIONS_CALLBACK,
    displayOptions,
    errorCode,
  };
}

export function readCenterEnrolledSeats(center_id: string) {
  return {
    type: actionTypes.CENTER_READ_ENROLLED_SEATS,
    center_id,
  };
}

export function callbackReadCenterEnrolledSeats(list: any[] | undefined, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_READ_ENROLLED_SEATS_CALLBACK,
    list,
    errorCode,
  };
}

export function readCenterPostEnrollmentReviews(center_id: string) {
  return {
    type: actionTypes.CENTER_READ_POST_ENROLLMENT_REVIEWS_LOADING,
    center_id,
  };
}

export function callbackReadCenterPostEnrollmentReviews(center_id: string, reviewRequestsSentCount: any | undefined, errorCode: string | undefined) {
  if (errorCode) {
    return {
      type: actionTypes.CENTER_READ_POST_ENROLLMENT_REVIEWS_ERROR,
      center_id,
      errorCode,
    };
  }

  return {
    type: actionTypes.CENTER_READ_POST_ENROLLMENT_REVIEWS_SUCCESS,
    reviewRequestsSentCount,
    center_id,
    errorCode,
  };
}

export function sendPostEnrollmentReviewRequestEmails(center_id: string) {
  return {
    type: actionTypes.CENTER_SEND_POST_ENROLLMENT_REVIEW_REQUEST_EMAILS_LOADING,
    center_id,
  };
}

export function resetSendPostEnrollmentReviewRequestEmails(center_id: string) {
  return {
    type: actionTypes.CENTER_SEND_POST_ENROLLMENT_REVIEW_REQUEST_EMAILS_RESET,
    center_id,
  };
}

export function callbackSendPostEnrollmentReviewRequestEmails(center_id: string, errorCode: string | undefined) {
  if (errorCode) {
    return {
      type: actionTypes.CENTER_SEND_POST_ENROLLMENT_REVIEW_REQUEST_EMAILS_ERROR,
      center_id,
      errorCode,
    };
  }

  return {
    type: actionTypes.CENTER_SEND_POST_ENROLLMENT_REVIEW_REQUEST_EMAILS_SUCCESS,
    center_id,
    errorCode,
  };
}

export function readCenterEnrollments(center_id: string) {
  return {
    type: actionTypes.CENTER_READ_ENROLLMENTS,
    center_id,
  };
}

export function callbackReadCenterEnrollments(center_id: string, enrollments: any[] | undefined, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_READ_ENROLLMENTS_CALLBACK,
    enrollments,
    center_id,
    errorCode,
  };
}

export function saveCenterEnrollment(center_id: string, parent, child, amount) {
  return {
    type: actionTypes.CENTER_SAVE_ENROLLMENT_LOADING,
    center_id,
    parent,
    child,
    amount,
  };
}

export function callbackSaveCenterEnrollment(center_id: string, parent, child, amount, errorCode: string | undefined) {
  if (errorCode) {
    return {
      type: actionTypes.CENTER_SAVE_ENROLLMENT_ERROR,
      center_id,
      parent,
      child,
      amount,
      errorCode,
    };
  }

  return {
    type: actionTypes.CENTER_SAVE_ENROLLMENT_SUCCESS,
    center_id,
    parent,
    child,
    amount,
    errorCode,
  };
}

export function listAgeGroups(center_id: string) {
  return {
    type: actionTypes.CENTER_AGEGROUPS,
    center_id,
  };
}

export function callbackListAgeGroups(ageGroups: AgeGroup[] | undefined, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_AGEGROUPS_CALLBACK,
    ageGroups,
    errorCode,
  };
}

export function setAgeGroups(center_id: string, ageGroups: AgeGroup[]) {
  return {
    type: actionTypes.CENTER_SET_AGEGROUPS,
    center_id,
    ageGroups,
  };
}

export function callbackSetAgeGroups(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_SET_AGEGROUPS_CALLBACK,
    success,
    errorCode,
  };
}

export function listQuestions(center_id: string, includeInternal: boolean, form: "waitlist" | "deposit" | "both") {
  return {
    type: actionTypes.CENTER_QUESTIONS,
    center_id,
    includeInternal,
    form,
  };
}

export function callbackListQuestions(questions: any[], centerCount: number, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_QUESTIONS_CALLBACK,
    questions,
    centerCount,
    errorCode,
  };
}

export function listWaitlist(center_id: string, states: string[] | undefined) {
  return {
    type: actionTypes.CENTER_WAITLISTS,
    center_id,
    states,
  };
}

export function callbackListWaitlist(spots: WaitlistSpot[], ageGroups: AgeGroup[] | undefined, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_WAITLISTS_CALLBACK,
    spots,
    ageGroups,
    errorCode,
  };
}

export function addCenter(center: Center) {
  return {
    type: actionTypes.CENTER_ADD,
    center,
  };
}

export function callbackAddCenter(center_id: string, success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_ADD_CALLBACK,
    center_id,
    success,
    errorCode,
  };
}

export function updateCenter(center: Center) {
  return {
    type: actionTypes.CENTER_UPDATE,
    center,
  };
}

export function callbackUpdateCenter(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_UPDATE_CALLBACK,
    success,
    errorCode,
  };
}

export function updateCenterQuestions(center_id: string, questions: any) {
  return {
    type: actionTypes.CENTER_UPDATE_QUESTIONS,
    center_id,
    questions,
  };
}

export function callbackUpdateCenterQuestions(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_UPDATE_QUESTIONS_CALLBACK,
    success,
    errorCode,
  };
}

export function updateCenterProfileCMS(center_id: string, summary: string | undefined, seo_text: string | undefined,
  about: string | undefined, about_image_url: string | undefined, education_philosophy: string | undefined,
  special: string | undefined, special_image_url: string | undefined, safety: string | undefined,
  safety_image_url: string | undefined, banner_image_url: string | undefined, single_image_banner: boolean | undefined,
  gallery_result_url: string | undefined, tour_video_url: string | undefined, social_links: {[s: string]: string},
  sections: {[s: string]: any}, center_basics: CenterBasics | undefined, profile_images: string[] | undefined) {
  return {
    type: actionTypes.CENTER_UPDATE_PROFILE_CMS,
    center_id,
    summary,
    seo_text,
    about,
    about_image_url,
    education_philosophy,
    special,
    special_image_url,
    safety,
    safety_image_url,
    banner_image_url,
    single_image_banner,
    gallery_result_url,
    tour_video_url,
    social_links,
    sections,
    center_basics,
    profile_images,
  };
}

export function callbackUpdateCenterProfileCMS(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_UPDATE_PROFILE_CMS_CALLBACK,
    success,
    errorCode,
  };
}

export function updateCenterTourSchedules(center_id: string, tourSchedules: TourSchedule[]) {
  return {
    type: actionTypes.CENTER_UPDATE_TOUR_SCHEDULES,
    center_id,
    tourSchedules,
  };
}

export function callbackUpdateCenterTourSchedules(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_UPDATE_TOUR_SCHEDULES_CALLBACK,
    success,
    errorCode,
  };
}

export function updateCenterTourScheduleVacation(center_id: string, vacationDays: Date[]) {
  return {
    type: actionTypes.CENTER_UPDATE_TOUR_SCHEDULE_VACATION,
    center_id,
    vacationDays,
  };
}

export function callbackUpdateCenterTourScheduleVacation(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_UPDATE_TOUR_SCHEDULE_VACATION_CALLBACK,
    success,
    errorCode,
  };
}

export function updateCenterDisplayOptions(center_id: string, displayOptions: CenterDisplayOptions) {
  return {
    type: actionTypes.CENTER_UPDATE_DISPLAY_OPTIONS,
    center_id,
    displayOptions,
  };
}

export function callbackUpdateCenterDisplayOptions(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_UPDATE_DISPLAY_OPTIONS_CALLBACK,
    success,
    errorCode,
  };
}

export function deleteCenter(center_id: string, safe: boolean) {
  return {
    type: actionTypes.CENTER_DELETE,
    center_id,
    safe,
  };
}

export function callbackDeleteCenter(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_DELETE_CALLBACK,
    success,
    errorCode,
  };
}

export function sendWaitlistForm(center_id: string, tour_id: string | undefined, parent_ids: string[]) {
  return {
    type: actionTypes.CENTER_SEND_WAITLIST_FORM,
    center_id,
    tour_id,
    parent_ids,
  };
}

export function callbackSendWaitlistForm(success: boolean, errorCode: string | undefined) {
  return {
    type: actionTypes.CENTER_SEND_WAITLIST_FORM_CALLBACK,
    success,
    errorCode,
  };
}
